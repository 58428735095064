import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Button, TextField, Grid, CircularProgress, Modal,
  IconButton, Typography, Paper, Box
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import ClearIcon from '@mui/icons-material/Clear';
import ImageIcon from '@mui/icons-material/Image';
import SearchIcon from '@mui/icons-material/Search';
import DataCard from "../../components/dataCard/DataCard";
import PopupContentBox from "../../components/popupContentBox/PopupContentBox";
import isAuth from "../../lib/isAuth";

const urls = {
  images: `https://esearch.whats-viral.me/imagesearch`,
};

const ContentPage2 = ({ category, setSyncTime }) => {
  const [data, setData] = useState([]);
  const [modalData, setModalData] = useState(null);
  const [currentActiveIndex, setCurrentActiveIndex] = useState(-1);
  const [dataLoader, setDataLoader] = useState(false);
  const [skipOffset, setSkipOffset] = useState(0);
  const [finished, setFinished] = useState(false);
  const [open, setOpen] = useState(false);
  const [totalResultCount, setTotalResultCount] = useState(0);

  const [filterInput, setFilterInput] = useState({
    searchText: "",
    minDate: null,
    maxDate: null,
    image: null,
    distance: 100,
  });

  const [filter, setFilter] = useState({
    searchText: "",
    minDate: null,
    maxDate: null,
    image: null,
    distance: 100,
  });

  const fetchData = async (reset = false) => {
    setDataLoader(true);
    let skipOffsetTemp = reset ? 0 : skipOffset;
    let dataTemp = reset ? [] : [...data];

    const params = new URLSearchParams();
    if (filter.searchText) params.append('query', filter.searchText);
    if (filter.minDate) params.append('dateMin', filter.minDate.toISOString());
    if (filter.maxDate) params.append('dateMax', filter.maxDate.toISOString());
    params.append('distance', filter.distance);
    params.append('skip', skipOffsetTemp);
    params.append('limit', 20);

    try {
      let response;
      if (filter.image) {
        const formData = new FormData();
        formData.append('image', filter.image);
        response = await axios.post(`${urls[category]}?${params.toString()}`, formData, {
          headers: {
            'Authorization': `Bearer ${isAuth()}`,
            'Content-Type': 'multipart/form-data',
          },
        });
      } else {
        response = await axios.post(`${urls[category]}?${params.toString()}`, null, {
          headers: {
            'Authorization': `Bearer ${isAuth()}`,
          },
        });
      }

      const newData = response.data.results;
      setData([...dataTemp, ...newData]);
      setSkipOffset(skipOffsetTemp + newData.length);
      setFinished(newData.length === 0);
      setTotalResultCount(response.data.total_result_count);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setDataLoader(false);
    }
  };

  useEffect(() => {
    if (filter.searchText || filter.image) {
      fetchData(true);
    } else {
      setData([]);
      setFinished(false);
      setSkipOffset(0);
      setTotalResultCount(0);
    }
  }, [filter]);

  const handleScroll = (e) => {
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (!finished && !dataLoader && offsetHeight + scrollTop >= scrollHeight - 100) {
      fetchData();
    }
  };

  const handleSearch = () => {
    setFilter({ ...filterInput });
  };

  const handleReset = () => {
    setFilterInput({
      searchText: "",
      minDate: null,
      maxDate: null,
      image: null,
      distance: 100,
    });
    setFilter({
      searchText: "",
      minDate: null,
      maxDate: null,
      image: null,
      distance: 100,
    });
  };

  const handleClose = () => {
    setOpen(false);
    setModalData(null);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              label="Search Text"
              value={filterInput.searchText}
              onChange={(e) => setFilterInput({ ...filterInput, searchText: e.target.value, image: null })}
              InputProps={{
                endAdornment: filterInput.searchText && (
                  <IconButton onClick={() => setFilterInput({ ...filterInput, searchText: '' })}>
                    <ClearIcon />
                  </IconButton>
                ),
              }}
              disabled={!!filterInput.image}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <input
              type="file"
              accept="image/*"
              style={{ display: 'none' }}
              id="image-upload"
              onChange={(e) => setFilterInput({ ...filterInput, image: e.target.files[0], searchText: '' })}
            />
            <label htmlFor="image-upload">
              <Button
                variant="outlined"
                component="span"
                startIcon={<ImageIcon />}
                disabled={!!filterInput.searchText}
                style={{ width: '100%', justifyContent: 'flex-start', height: '56px' }}
              >
                {filterInput.image ? filterInput.image.name : 'Upload Image'}
              </Button>
            </label>
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              fullWidth
              type="number"
              label="Distance"
              value={filterInput.distance}
              onChange={(e) => setFilterInput({ ...filterInput, distance: e.target.value })}
              InputProps={{ inputProps: { min: 0 } }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="Start Date"
                value={filterInput.minDate}
                onChange={(newValue) => setFilterInput({ ...filterInput, minDate: newValue })}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="DD/MM/yyyy"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DesktopDatePicker
                label="End Date"
                value={filterInput.maxDate}
                onChange={(newValue) => setFilterInput({ ...filterInput, maxDate: newValue })}
                renderInput={(params) => <TextField {...params} fullWidth />}
                inputFormat="DD/MM/yyyy"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={handleSearch}
              startIcon={<SearchIcon />}
              disabled={dataLoader}
              style={{ height: '56px' }}
            >
              Search
            </Button>
          </Grid>
          <Grid item xs={6} md={3}>
            <Button
              fullWidth
              variant="outlined"
              onClick={handleReset}
              disabled={dataLoader}
              style={{ height: '56px' }}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <div
        style={{
          flexGrow: 1,
          overflow: "auto",
          padding: "20px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        onScroll={handleScroll}
      >
        {data.length === 0 && !dataLoader && (
          <Typography variant="h6" style={{ padding: "40px" }}>
            No results found. Please initiate a search.
          </Typography>
        )}
        {data.map((ele, index) => (
          <DataCard
          metadata={ele}
          onClick={() => {
            console.log(ele);
            setModalData({ ...ele});
            setCurrentActiveIndex(index);
            setOpen(true);
          }}
          category={category}
        />
        ))}
        {dataLoader && <CircularProgress style={{ margin: "20px" }} />}
      </div>

      <Box
        sx={{
          padding: 2,
          borderTop: 1,
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body2">
          {data.length} out of {totalResultCount} results loaded
        </Typography>
      </Box>

      <Modal open={open} onClose={handleClose}>
        {modalData && (
          <PopupContentBox
            category={modalData.contentType ? `${modalData.contentType}s` : category}
            modalData={modalData}
            handleClose={handleClose}
            isOpen={open}
            setData={setData}
            setModalData={setModalData}
            currentActiveIndex={currentActiveIndex}
            filter={filter}
            isForwarded={category === "forwarded"}
          />
        )}
      </Modal>
    </div>
  );
};

export default ContentPage2;