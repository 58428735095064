const constants = {
  // baseUrl: "http://localhost:3434",
  // baseUrl: "https://analysis-backendx.whats-viral.me",
  baseUrl: "https://analysis-backend.whats-viral.me",
  imgUrl:   
    "https://cdn.blankstyle.com/files/imagefield_default_images/notfound_0.png",
  // apiBaseUrl: "http://localhost:3434",
  // apiBaseUrl: "https://analysis-backendx.whats-viral.me",
  apiBaseUrl: "https://analysis-backend.whats-viral.me",
  facebookAppId: "1267314400718415",
  // apiLogin: "http://localhost:3434/auth/login",
  // apiLogin: "https://analysis-backendx.whats-viral.me/auth/login",
  apiLogin: "https://analysis-backend.whats-viral.me/auth/login",
  linkImgUrl: "https://i.ibb.co/WgPVtbD/link-6931554.png",
};

export default constants;
