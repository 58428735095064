import FlagIcon from "@mui/icons-material/Flag";
import constants from "../../assets/constants";
import { userPlatform } from "../../lib/isAuth";
import {useState, useEffect} from "react";
import axios from 'axios';

const formatTimestamp = (date) => {
  const t = new Date(date);
  const utcDate = new Date(Date.UTC(
    t.getUTCFullYear(),
    t.getUTCMonth(),
    t.getUTCDate(),
    t.getUTCHours(),
    t.getUTCMinutes(),
    t.getUTCSeconds()
  ));

  const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
  return (
    ("0" + utcDate.getUTCDate()).slice(-2) +
    " " +
    months[utcDate.getUTCMonth()] 
  );
}

const MiniTextCard = ({ metadata, onClick, display }) => {
  let displayData = "";
  switch(display){
    case "forwarded":
      let forwardingScore = metadata.maxForwardingScore;
      if(userPlatform() == "whatsapp" && forwardingScore == 127){
        forwardingScore = "Many"
      }
      if(userPlatform() == "facebook"){
        displayData = `Likes: ${forwardingScore}`;
      }
      else{
        displayData = `Forwards: ${forwardingScore}`;
      }
      break;
    case "uniqueChats":
      displayData = `Unique ${userPlatform() == "facebook" ? "Pages/Groups" : "Chats"}: ${metadata.uniqueChatnamesCount}`;
      break;
    default:
      displayData = `Frequency: ${metadata.frequency}`;
  }

  const [isFlagged, setIsFlagged] = useState(false);
  const [avgMisInfo, setAvgMisInfo] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const flagCheckUrl = `${constants.baseUrl}/flags/isflagged/${metadata.id}`;
        console.log(metadata)
        const response = await axios.post(flagCheckUrl, {
          contentType: 'message'
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        // console.log(response.data)
        setIsFlagged(response.data.isFlagged);
        setAvgMisInfo(response.data.averageMisInfo);
      } catch (error) {
        console.error('Error fetching flag status:', error);
      }
    };

    fetchData();
  // }, [metadata]); 
  }, []); 

  return (
    <div
      style={{
        width: "300px",
        height: "300px",
        margin: "20px",
        cursor: "pointer",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px",
        textAlign: "center",
        display: "flex",
        padding: "8px",
        flexDirection: "column",
        position: "relative",
        background: avgMisInfo > 90 ? "rgba(247, 55, 7, 1)" : (avgMisInfo > 45 ? "rgba(255, 233, 0, 1)" : "white")
      }}
      onClick={onClick}
    >
      <div
          style={{
            position: "absolute",
            top: 15,
            right: 15,
            padding: "3px 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            borderRadius: "5px",
            background: "rgb(32, 222, 211)",
            fontWeight: "600",
            color: "white",
            fontSize: "0.85rem",
          }}
        >
          {formatTimestamp(metadata.latestTimestamp)}
      </div>
      <div
        style={{
          flex:1,
          padding: "10px",
          boxSizing: "border-box",
          display: "flex",
          overflow:"hidden",
          justifyContent: "center",
          alignItems:"center",
          background: "white",
        }}
      >
        <div style={{ 
          whiteSpace: "pre-line",
          textAlign: "center",
          overflow: "hidden",
          margin: "auto",
        }}>
          {metadata.content}
        </div>
      </div>
      {isFlagged && (
        <div
          style={{
            position: "absolute",
            top: 15,
            left: 15,
            background: "#EF476F",
            padding: "3px 18px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
            borderRadius: "5px",
          }}
        >
          <FlagIcon sx={{ color: "white", fontSize: "1.3rem" }} />
        </div>
      )}
      <div
        style={{
          background: "#00CCFF",
          fontWeight: "600",
          color: "white",
          padding: "3px",
          boxSizing: "border-box",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontSize: "1.1rem",
        }}
      >
        {displayData}
      </div>
    </div>
  );
};

export default MiniTextCard;
