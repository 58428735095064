import CloseIcon from "@mui/icons-material/Close";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { isMobile } from "react-device-detect";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const PopupGroupNames = ({ handleClose, popupLoader, groupNames }) => {
  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        background: "white",
        borderRadius: "10px",
        padding: "0px",
        outline: "none",
        display: "flex",
        width: isMobile ? "70vw" : "50vw",
        overflow: "hidden",
        maxHeight: isMobile ? "80vh" : "70vh",
        position: "relative",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      {popupLoader ? (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              boxSizing: "border-box",
              overflow: "auto",
              // justifyContent: "center",
            }}
          >
            <div
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                padding: "15px",
                marginBottom: "10px",
              }}
            >
              Group Names  
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "1rem",
                padding: "15px",
                marginBottom: "10px",
              }}
            >
              Total Groups: {groupNames.length}
            </div>
            <TableContainer >
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="right">Text Messages</TableCell>
                    <TableCell align="right">Images</TableCell>
                    <TableCell align="right">Videos</TableCell>
                    <TableCell align="right">Audios</TableCell>
                    <TableCell align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupNames.map((row) => (
                    <TableRow
                      key={row.chatname}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.chatname.length > 60 ? `${row.chatname.substring(0, 60)}...` : row.chatname}
                      </TableCell>
                      <TableCell align="right">{row.message || 0}</TableCell>
                      <TableCell align="right">{row.image || 0}</TableCell>
                      <TableCell align="right">{row.video || 0}</TableCell>
                      <TableCell align="right">{row.audio || 0}</TableCell>
                      <TableCell align="right">{row.total || 0}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}
      <IconButton
        style={{
          position: "absolute",
          right: "5px",
          top: "5px",
          borderRadius: "50%",
          padding: isMobile ? "5px" : "10px",
          cursor: "pointer",
          textAlign: "center",
          transition: "0.3s ease",
          background: "rgba(255,255,255,0.5)",
        }}
        onClick={() => handleClose()}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default PopupGroupNames;
